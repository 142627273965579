@import '@hh.ru/magritte-ui/breakpoints';

.profile-content {
    width: 100%;
}

.profile-container {
    position: relative;
}

.popup-actions {
    top: 0;
    left: 8px;
    width: calc(100% - 16px);
    position: absolute;
    display: flex;
    justify-content: flex-end;

    .screen-gt-xs({
        top: 16px;
        left: 16px;
        width: calc(100% - 32px);
    });
}

.panel-content {
    padding: 20px 4px;

    .screen-gt-xs({
        padding: 20px 12px;
    });
}

.header-content {
    padding: 16px 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .screen-gt-xs({
        padding: 32px 24px;
    });
}

.header-content-name {
    text-align: center;
    width: calc(100% - 80px);
}

.footer {
    padding: 0 16px 16px;

    .screen-gt-xs({
        padding: 0 24px 24px;
    });
}

.fullwidth-form {
    width: 100%;
}

.employer-name-container {
    display: flex;
    align-items: center;
    gap: 6px;
    --magritte-ui-text-color-override: initial;
    --magritte-ui-icon-color-override: initial;
}

.employer-name-container svg {
    flex-shrink: 0;
}

@informers-gap: 8px;

.informers {
    flex-wrap: nowrap;
    display: flex;
    gap: @informers-gap;

    .screen-gt-xs({
        flex-wrap: wrap;
    });
}

.informers-item {
    white-space: nowrap;

    .screen-gt-xs({
        white-space: initial;
        width: calc(50% - @informers-gap / 2);
    });
}

.informers-item-skeleton {
    border-radius: 20px;
    height: 80px;
    flex-basis: calc(50% - @informers-gap / 2);
}

.informers-item-scrollable:first-child {
    flex-grow: 1;
}

.informers-item:first-child:last-child {
    width: 100%;
}

.clickme-balance,
.clickme-bonus {
    white-space: nowrap;
}

.clickme-bonus-text-container {
    overflow: hidden;
}

.clickme-bonus {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    min-width: 0;
}

.clickme-bonus-icon-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.clickme-bonus-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup-content {
    padding: 0 16px;

    .screen-gt-xs({
        padding: 0 24px;
    });
}
