@import '~bloko/common/styles/_layers';
@import '~src/styles/mixins/print';
@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.float-chatik-activator {
    .noprint();

    cursor: pointer;
    position: fixed;
    right: 16px;
    bottom: 94px;
    z-index: @floating;

    display: flex;
    align-items: center;
    column-gap: 12px;

    height: 40px;
    width: 194px;
    padding: 12px;
    border-radius: @magritte-static-border-radius-600;
    border: 1px solid @magritte-color-stroke-neutral;
    background-color: @magritte-color-background-primary;
    box-shadow: @magritte-shadow-level-3-x @magritte-shadow-level-3-y @magritte-shadow-level-3-blur
        @magritte-shadow-level-3-spread @magritte-shadow-level-3-color;
}

.activator-title {
    flex: 1 0 auto;

    .magritte-typography-subtitle-1-semibold();
}

.indicators {
    display: flex;
    align-items: center;
    gap: 4px;
}

.logo {
    flex-shrink: 0;
    background-image: url('../../styles/images/logos/hh.ru.svg?v=23122024');
    background-size: contain;
    background-repeat: no-repeat;

    width: 40px;
    height: 40px;
}

.icon {
    display: flex;
    padding: 8px;
}
